import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ButtonIcon from "../../icon/buttonIcon"

const BloomMoreBranding = () => {
  return (
    <section className="home-cs-listing branding-listing">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2 className="sub-title">
              More Branding Projects
            </h2>
          </Col>
        </Row>
        <Row className="wrapper-main">          
            <Col lg={4} className="mt-md-0 mt-3">
                <Link to="/branding/dhara-ayur-essentials/" className="home-cs-link first">
                    <div className="hcs-img">
                    <StaticImage
                        src="../../images/branding/dhara-ayur-essentials/dhara-ayur-essentials-Branding-listing-image.png"
                        alt="Dhara Ayur Essentials Branding Project | WowMakers"
                        placeholder="blurred"
                        width={507}
                        height={354}
                        className="img-fluid"
                    />
                    </div>
                    <div className="hcs-content branding">
                    <span className="section-tag">Branding<span className="seperator"><span>.</span></span>Health & Wellness</span>
                    <div className="cs-header">
                        <h3>
                            Dhara Ayur Essentials
                        </h3>
                    </div>
                    <div className="btn wowaction-btn">
                        <ButtonIcon />
                        <span>Read More</span>
                    </div>
                    </div>
                </Link>
            </Col>
            <Col lg={4} className="mt-lg-0 mt-3">
                <Link to="/branding/mayabae/" className="home-cs-link middle">
                <div className="hcs-img">
                    <StaticImage
                    src="../../images/branding/mayabae/mayabae-brand-listing.png"
                    alt="MayaBae Branding Project | WowMakers"
                    placeholder="blurred"
                    width={507}
                    height={354}
                    className="img-fluid"
                    />
                </div>
                <div className="hcs-content branding">
                    <span className="section-tag">Branding<span className="seperator"><span>.</span></span>E-commerce</span>
                    <div className="cs-header">
                    <h3>
                        MayaBae
                    </h3>
                    </div>
                    <div className="btn wowaction-btn">
                    <ButtonIcon />
                    <span>Read More</span>
                    </div>
                </div>
                </Link>
            </Col>
            <Col lg={4} className="mt-lg-0 mt-3">
                <Link to="/branding/xhale/" className="home-cs-link last">
                <div className="hcs-img">
                    <StaticImage
                    src="../../images/branding/xhale/xhale-listing-image.png"
                    alt="Xhale Branding Project | WowMakers"
                    placeholder="blurred"
                    width={507}
                    height={354}
                    className="img-fluid"
                    />
                </div>
                <div className="hcs-content branding">
                    <span className="section-tag">Branding<span className="seperator"><span>.</span></span>Health & Wellness</span>
                    <div className="cs-header">
                    <h3>
                        Xhale
                    </h3>
                    </div>
                    <div className="btn wowaction-btn">
                    <ButtonIcon />
                    <span>Read More</span>
                    </div>
                </div>
                </Link>
            </Col>
        </Row>
      </Container>
    </section>
  )
}

export default BloomMoreBranding
