import React from "react"
import { Link } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"

const BloomMain = () => {
  return (
    <div className="main-branding">
        <Container>
            <Row>
                <Col xl={6} xs={12} className="branding-main">
                    <p className="top-link-back"> <span><Link to="/branding" >Branding</Link> </span></p>
                    <h1 className="b-header">
                        Bloom Botanics
                    </h1>
                    <p class="pt-3">Discover how WowMakers revitalized Beebom's digital presence. Our branding expertise brought their tech vision to life.</p>
                    <Col md={12} className="b-bottom px-0">
                        <p className="sub-title bstitle mb-0">Health & Wellness</p>                
                    </Col>
                </Col>
            </Row>
        </Container>            
    </div>    
  )
}

export default BloomMain
