import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const BloomFullwidth = () => {
  return (
    <section className="rivian-full-width py-0">        
        <Container fluid>
            <Row>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-1.jpg" alt="Bloom Botanics Brand Identity Presentation 1 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-2.jpg" alt="Bloom Botanics Brand Identity Presentation 2 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-3.jpg" alt="Bloom Botanics Brand Identity Presentation 3 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-4.jpg" alt="Bloom Botanics Brand Identity Presentation 4 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-5.jpg" alt="Bloom Botanics Brand Identity Presentation 5 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-6.jpg" alt="Bloom Botanics Brand Identity Presentation 6 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-7.jpg" alt="Bloom Botanics Brand Identity Presentation 7 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-8.jpg" alt="Bloom Botanics Brand Identity Presentation 8 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-9.jpg" alt="Bloom Botanics Brand Identity Presentation 9 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-10.jpg" alt="Bloom Botanics Brand Identity Presentation 10 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-11.jpg" alt="Bloom Botanics Brand Identity Presentation 11 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-12.jpg" alt="Bloom Botanics Brand Identity Presentation 12 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-13.jpg" alt="Bloom Botanics Brand Identity Presentation 13 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-14.jpg" alt="Bloom Botanics Brand Identity Presentation 14 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-15.jpg" alt="Bloom Botanics Brand Identity Presentation 15 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-16.jpg" alt="Bloom Botanics Brand Identity Presentation 16 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-17.jpg" alt="Bloom Botanics Brand Identity Presentation 17 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-18.jpg" alt="Bloom Botanics Brand Identity Presentation 18 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-19.jpg" alt="Bloom Botanics Brand Identity Presentation 19 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-20.jpg" alt="Bloom Botanics Brand Identity Presentation 20 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-21.jpg" alt="Bloom Botanics Brand Identity Presentation 21 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-22.jpg" alt="Bloom Botanics Brand Identity Presentation 22 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-23.jpg" alt="Bloom Botanics Brand Identity Presentation 23 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-24.jpg" alt="Bloom Botanics Brand Identity Presentation 24 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-25.jpg" alt="Bloom Botanics Brand Identity Presentation 25 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-26.jpg" alt="Bloom Botanics Brand Identity Presentation 26 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-27.jpg" alt="Bloom Botanics Brand Identity Presentation 27 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-28.jpg" alt="Bloom Botanics Brand Identity Presentation 28 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-29.jpg" alt="Bloom Botanics Brand Identity Presentation 29 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-30.jpg" alt="Bloom Botanics Brand Identity Presentation 30 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-31.jpg" alt="Bloom Botanics Brand Identity Presentation 31 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>
                <Col lg={12} className="px-0">
                    <StaticImage src="../../../images/branding/bloom-botanics/Bloom-Botanics-branding-32.jpg" alt="Bloom Botanics Brand Identity Presentation 32 | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width cs-width-large" width={1920} height={1080}/>
                </Col>                                                                                                                                
            </Row>
        </Container>
    </section>
  )
}

export default BloomFullwidth