import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import BloomMain from "../../components/branding/bloom-botanics/bloomMain"
import BloomFullwidth from "../../components/branding/bloom-botanics/bloomFullWidth"
import BloomMoreBranding from "../../components/branding/bloom-botanics/bloomMoreBranding"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/branding/bloom.scss"

import brandingFeaturedImage from "../../../static/branding/bloom-botanics/bloom-botanics-featured-image.png"
export const Head = () => (
    <>
        <SeoHead 
            ogImage={brandingFeaturedImage}
            title="Crafting Nature's Brand: Bloom Botanics | WowMakers"
            description="See how we captured the essence of Bloom Botanics in a fresh, organic brand identity. Natural beauty meets strategic design."
        />
    </>
)

const BloomBotanicsBranding = () => {
    
    return (
        <Layout>
            <Seo
                bid="branding-bloom-botanics"
                bclass="case-studies-page branding"
            ></Seo>
            <ScrollIndicationHeightZero />
            <BloomMain />
            <BloomFullwidth />
            <BloomMoreBranding />
        </Layout>
    )
}

export default BloomBotanicsBranding
